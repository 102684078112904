import * as React from "react"

import MenuPage from "../components/menu-page"
import Subscribe from "../components/subscribe"
import Footer from "../components/footer"
import SEO from "../components/seo"

const AdvancedToolPage = () => (
  <div>
    <SEO 
        title="Advanced Tools - Advanced capability done effortless" 
        description="We’ve enclosed a number of Advanced Tools inside the Sheephostingbay Web Control Panel."
    />
    <div className="sub-section-wrap">
          <MenuPage/>
    </div>

    <div className="py-12 mt-5 pb-0">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mx-auto">
                <p className="section-title"> <span className="block text-indigo-600 xl:inline">Advanced</span> Tool <span role="img" aria-label="emoji">✨ </span></p>
                <p className="section-after-title">Advanced capability done effortless</p>
                <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-center">
                  <div className="mt-3 sm:mt-0 sm:ml-3">
                      <a 
                      href="https://demo.hepsia.com/advanced/?auto_login=true" 
                      rel="noreferrer" 
                      target="_blank" 
                      className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 md:py-4 md:text-lg md:px-10">
                      View Demo
                      </a>
                  </div>
              </div>
              <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-center">
                  <div className="mt-3 sm:mt-0 sm:ml-3">
                    <p className="m-0">
                        <img src="https://sheephostingbay.duoservers.com/template2/img/advanced-tools-banner.webp" alt="Sheephostingbay Web control panel"/>
                    </p>
                  </div>
              </div>
              
            </div>
        </div>
        
    </div>

    <div className="py-12 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="text-center mx-auto">
                 <p className="section-after-title">
                 <span role="img" aria-label="emoji">🦄</span> We’ve enclosed a number of Advanced Tools inside the Sheephostingbay Web Control Panel. 
                 These tools will assist you to do easy tasks like protecting a folder using a security password or way more complex ones such as, 
                 for instance, altering the PHP version of your cloud hosting account. Even though the duties they actually do vary, all the 
                 Advanced Tools have one similarity. They are truly straightforward and involve absolutely no particular expertise from you.
                </p>
              
            </div>
        </div>
        
    </div>

    <div className="py-32 server-bg">
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="overflow-hidden bg-white shadow-xl dark:bg-gray-800 sm:rounded-lg">
              <div>
                  <div className="grid grid-cols-1 bg-gray-200 bg-opacity-25 dark:bg-gray-800 md:grid-cols-2">
                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <img src="https://sheephostingbay.duoservers.com/template2/img/hotlink-protection.webp" className="w-60" alt="Hotlink Protection" />
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">Hotlink Protection</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> 
                                    Protect yourself from bandwidth theft<br/><br/>
                                    From your Sheephostingbay Web Control Panel, you will be able to instantaneously secure all of the photographs 
                                    on your sites from getting used anywhere else devoid of your authorization. And you can make this happen with 
                                    several clicks of your computer mouse applying the Hotlink Protection tool.
                                    <br/><br/>
                                    Everything you need to do is simply pick the domain you would want to guard and then start the Hotlink Protection. 
                                    Modifications are going to take effect instantly and your pics are going to be guarded from unwanted use and 
                                    traffic thievery.
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">.htaccess generator</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> 
                                    Auto generation of .htaccess files<br/><br/>
                                    With the help of the .htaccess file, it’s easy to take control of your website – create redirections, set up 
                                    password shielded directories, and so much more. From the Sheephostingbay Web Control Panel, you can quickly 
                                    develop completely new .htaccess files applying Sheephostingbay’s highly effective .htaccess Generator.
                                    <br/><br/>
                                    You don’t need to learn a single thing as related to .htaccess files so as to operate our .htaccess Generator. 
                                    Just state the adjustments you prefer to generate using the tool’s uncomplicated interface and our the will 
                                    instantly create the necessary code for you.
                                    </div>
                                </div>
                                <img src="https://sheephostingbay.duoservers.com/template2/img/htaccess-generator.webp" className="w-60" alt=".htaccess generator" />
                            </div>
                        </div>
                      
                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <img src="https://sheephostingbay.duoservers.com/template2/img/ip-blocking.webp" className="w-60" alt="IP blocking" />
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">IP blocking</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> 
                                    Block malevolent IPs from your web site<br/><br/>
                                    If you need to block out a spammer from accessing your web site, a web troll out of your community forum or, 
                                    possibly a a pack of IPs flooding your website, it’s best to take advantage of our straightforward IP 
                                    blocking tool. It enables one to promptly prevent an IP address as well as a a whole IP array from 
                                    ever being able to view your site.
                                    <br/><br/>
                                    The IP Blocking tool is really intuitive. All you need do is enter in, the IP address (or addresses) which 
                                    you want to bar and click on the action button. All IPs you’ve inserted are going to be obstructed as 
                                    soon as possible.
                                    </div>
                                </div>
                            </div>
                        </div>   

                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">PHP configuration</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> 
                                    Customize the PHP settings for one’s web site<br/><br/>
                                    The Sheephostingbay Web Control Panel will give you the option to immediately switch the PHP release for 
                                    your account. You’ll be able to pick from earlier PHP editions to the most current stable PHP launch. 
                                    We’ve established an easy–to–navigate interface that permits you to customize the PHP release as 
                                    frequently as you desire. And the release you’ll have chosen is going to be used at once.
                                    <br/><br/>
                                    Regardless of what PHP edition you select, you will also have total control within the php.ini file – the 
                                    PHP settings file for your hosting account. It’s easy to modify some of the most preferred selections, 
                                    update the full php.ini file or quickly return all modifications and then set things to their normal 
                                    state.
                                    </div>
                                </div>
                                <img src="https://sheephostingbay.duoservers.com/template2/img/php-config.webp" className="w-60" alt="PHP configuration" />
                            </div>
                        </div>

                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <img src="https://sheephostingbay.duoservers.com/template2/img/cron-jobs.webp" className="w-60" alt="Cron Jobs" />
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">Cron Jobs</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> 
                                    Set automated planned jobs<br/><br/>
                                    If there’s a survey that you need to run daily, or if there are a few automatic tasks you wish your web 
                                    site to complete, then cron jobs are the thing you need. Within the Sheephostingbay Web Control Panel, 
                                    it’s easy to develop and handle all types of cron jobs. We have got made a quick way to generate a 
                                    cron job. It will require under a minute for any completely new job to be slated for execution.
                                    <br/><br/>
                                    Cron jobs are obtainable with all our cloud hosting deals. Each comes with a varied amount of cron jobs. 
                                    Extra cron jobs can be bought as an update any time you need.
                                    </div>
                                </div>
                            </div>
                        </div>  

                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">Password Protection</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> 
                                    Simple way to defend your data<br/><br/>
                                    If you would like to establish a location inside your site that can be seen only to the users you need, 
                                    it is easy to make full use of Sheephostingbay’s Password Protection tool. Using it you can quickly 
                                    create a username and password protected folder which is encrypted and available merely to you and 
                                    anybody else whom you share the logon information with.
                                    <br/><br/>
                                    The directory is going to be safeguarded without delay and no person else can check out precisely what 
                                    is on the inside. You can be the sole individual who’ll be capable of alter or switch it unless you 
                                    opt to approve different customers to view it.
                                    </div>
                                </div>
                                <img src="https://sheephostingbay.duoservers.com/template2/img/password-protection.webp" className="w-60" alt="Password Protection" />
                            </div>
                        </div>

                        <div className="p-6 col-span-2 border-t border-gray-200 dark:border-gray-700 md:border-t-1">
                            <div className="sm:flex items-center">
                                <img src="https://sheephostingbay.duoservers.com/template2/img/url-redirection.webp" className="w-60" alt="URL redirection" />
                                <div className="ml-4 text-lg leading-7 text-gray-600 dark:text-gray-200">
                                    <p className="font-semibold p-0 m-0">URL redirection</p>
                                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-400"> 
                                    URL re–direction with just a couple of clicks<br/><br/>
                                    If you want to rapidly divert your web site to another page or another web site, that can be done with 
                                    the URL Redirection instrument. It minimizes the need to create PHP redirects or set up an .htaccess 
                                    file. Simply select the place you wish to point your site towards and the software instrument will 
                                    handle the rest. Your web site will be rerouted immediately.
                                    <br/><br/>
                                    And when you wish to revoke the re–direction as well as have your website directed back to the previous 
                                    URL, you can do this with simply a click of the mouse.
                                    </div>
                                </div>
                            </div>
                        </div>  

                  </div>
              </div>
          </div>
      </div>
    </div>

    <Subscribe/>
    <Footer/>
  </div>
)

export default AdvancedToolPage
